<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div>
      <div class="pa-4">
        <RelatorioProdutoFormulario
          @send="exportar"
          :listProducts="listProducts"
          :listFuncionarios="listFuncionarios"
          :loadingBtn="loadingBtn"
          :listSectors="ListSectors"
          :listDistricts="ListDistricts"
          :listRegional="ListRegionals"
          :listSaleForce="ListSales"
          :listRolePermission="listRoles"
        />
        <div class="mt-8">
          <v-progress-circular
            v-if="loading"
            :size="100"
            :width="4"
            color="fmq_black"
            indeterminate
            class="d-flex mx-auto py-8 mt-8"
          ></v-progress-circular>
          <div v-else>
            <div v-if="tableData.length">
              <div class="d-flex justify-space-between align-center my-4">
                <span>Dados do relatório:</span>
                <v-btn
                  depressed
                  color="green accent-4"
                  dark
                  :loading="loadingBtn"
                  outlined
                  small
                  @click="
                    formData.download = true;
                    exportar(formData);
                  "
                  >EXPORTAR XLS</v-btn
                >
              </div>
              <RelatorioTable :items="tableData" :headers="headers" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      :dialogMessage="dialogMessage"
      @close="goTo"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { exportStatisticProductTimes } from "@/services/statistic-product.js";
import { listProduct } from "@/services/product";
import { listEmployee } from "@/services/employee";
import { listSector } from "@/services/sector.js";
import { listDistrict } from "@/services/district.js";
import { listRegional } from "@/services/regional.js";
import { listSaleForce } from "@/services/sale-force";
import { listRolePermission } from "@/services/permission";
import RelatorioProdutoFormulario from "@/components/relatorios/RelatorioProdutoFormulario.vue";
import RelatorioTable from "@/components/relatorios/RelatorioTable.vue";
export default {
  name: "RelatorioTempoVisualizacaoProduto",
  components: { RelatorioProdutoFormulario, RelatorioTable },
  data: () => ({
    breadcrumbs: [
      {
        text: "Tempo de visualização de Produto",
        disabled: false,
        to: "/relatorio-tempo-de-visualizacao-de-produto",
      },
    ],
    error: false,
    success: false,
    loadingBtn: false,
    messageError: null,
    item: {},
    loading: false,
    listProducts: [],
    listFuncionarios: [],
    ListSectors: [],
    ListDistricts: [],
    ListRegionals: [],
    ListSales: [],
    listRoles: [],
    url: null,
    tableData: [],
    dialogMessage:
      "Relatório criado com sucesso, clique em OK para baixar o arquivo!",
    headers: [
      {
        text: "Intervalos",
        value: "dateSteps",
      },
      // { text: "Produto Id", value: "productId" },
      { text: "Produto Nome", value: "productName" },
      { text: "Total", value: "totalTime" },
      // { text: "Funcinário Id", value: "employeeId" },
      { text: "Funcinário Nome", value: "employeeName" },
      { text: "Funcinário Email", value: "employeeEmail" },
      // { text: "Setor Id", value: "sectorId" },
      { text: "Setor Nome", value: "sectorName" },
      // { text: "Distrital Id", value: "districtId" },
      { text: "Distrital Name", value: "districtName" },
      // { text: "Regional Id", value: "regionalId" },
      { text: "Regional Nome", value: "regionalName" },
      // { text: "Força de Venda Id", value: "saleForceId" },
      { text: "Força de Venda Nome", value: "saleForceName" },
      // { text: "Cargo Id", value: "roleId" },
      { text: "Cargo Nome", value: "roleName" },
    ],
    formData: {},
  }),
  created() {
    this.listarProdutos();
    this.listarFuncionarios();
    this.getListSector();
    this.getListDistrict();
    this.getListRegional();
    this.getListSaleForce();
    this.getListRolePermission();
  },
  methods: {
    async exportar(event) {
      this.formData = event;
      this.error = false;
      this.sucess = false;
      event.download ? (this.loadingBtn = true) : (this.loading = true);
      try {
        await exportStatisticProductTimes(event).then((resp) => {
          this.tableData = resp.data;
          this.url = resp.data.url;
          if (this.url) {
            this.success = true;
          }
        });
        this.loading = false;
        this.loadingBtn = false;
      } catch (e) {
        this.loading = false;
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async listarProdutos() {
      let data = new FormData();
      data.append("labels[]", "name");
      data.append("labels[]", "laboratory:name");
      const resp = await listProduct(data);
      this.listProducts = resp.data;
    },
    async listarFuncionarios() {
      const resp = await listEmployee();
      this.listFuncionarios = resp.data;
    },
    async getListSector() {
      const resp = await listSector();
      this.ListSectors = resp.data;
    },
    async getListDistrict() {
      const resp = await listDistrict();
      this.ListDistricts = resp.data;
    },
    async getListRegional() {
      const resp = await listRegional();
      this.ListRegionals = resp.data;
    },
    async getListSaleForce() {
      const resp = await listSaleForce();
      this.ListSales = resp.data;
    },
    async getListRolePermission() {
      const resp = await listRolePermission();
      this.listRoles = resp.data;
    },
    goTo() {
      this.success = false;
      window.open(this.url);
    },
  },
};
</script>

<style></style>
